@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
    @apply w-full max-w-full
}

.gallery {
    box-shadow: 16px 16px 0 rgb(140 24 241 / 25%), 8px 8px 0 rgb(147 24 230 / 50%);
}

.page-item  {
    background-color: #161617;

  }

  .text-shadow {
    text-shadow: 0 0 2px #000;
  }

  .drop-shadow {
    filter: drop-shadow(0 0px 2px rgb(0 0 0 ));
  }

  .overlay-badge {
    @apply opacity-90 drop-shadow text-white;
  }